.login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginForm {
  display: flex;
  flex-direction: column;
}

.loginInput {
  margin-bottom: 10px;
  padding: 5px;
}

.loginButton {
  background-color: teal;
  border: none;
  color: white;
  padding: 5px;
  cursor: pointer;
}
